window.jQuery = window.$ = require('jquery');
import Swiper from 'swiper/bundle';
require('bootstrap/js/src/tab.js');

var slideActiveOld = 0;

function loadSwiper() {
    setTimeout(function () {
        var $swiperSelector = $('.swiper-container');

        $swiperSelector.each(function(index) {
            var $this = $(this);
            var numberTX = 0;
            var flag = 0;
            $this.addClass('swiper-slider-' + index);

            var freeMode = $this.data('free-mode') ? $this.data('free-mode') : false;
            var loop = $this.data('loop') ? $this.data('loop') : false;
            var slidesDesktop = $this.data('slides-desktop') ? $this.data('slides-desktop') : 4;
            var slidesTablet = $this.data('slides-tablet') ? $this.data('slides-tablet') : 3;
            var slidesMobile = $this.data('slides-mobile') ? $this.data('slides-mobile') : 2;
            var spaceBetween = $this.data('space-between') ? $this.data('space-between'): 20;
            var spaceBetweenMobile = $this.data('space-between-mobile') ? $this.data('space-between-mobile'): 20;

            var swiper = new Swiper('.swiper-slider-' + index, {
                direction: 'horizontal',
                loop: loop,
                observer: true,
                observeParents: true,
                freeMode: freeMode,
                spaceBetween: spaceBetween,
                breakpoints: {
                    992: {
                        slidesPerView: slidesDesktop
                    },
                    768: {
                        slidesPerView: slidesTablet
                    },
                    320: {
                        slidesPerView: slidesMobile,
                        spaceBetween: spaceBetweenMobile
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                },
                on: {
                    init: function(swiper, event) {
                        setTimeout(function () {
                            //loadImg();
                        }, 1000);
                    },
                    scrollbarDragMove: function(swiper, event) {
                        if($(swiper.wrapperEl).parent().find('.swiper-drag').length) {
                            $(swiper.wrapperEl).parent().find('.swiper-drag').addClass('swiper-dragging');
                        }
                    },
                    scrollbarDragEnd: function(swiper, event) {
                        if($(swiper.wrapperEl).parent().find('.swiper-drag').length) {
                            $(swiper.wrapperEl).parent().find('.swiper-drag').removeClass('swiper-dragging');
                        }

                        disabledSwiperButton($(swiper.wrapperEl).parent());

                        const breakpoint = window.matchMedia('(max-width:767px)');
                        if (!breakpoint.matches) {
                            setMarginLeft($(swiper.wrapperEl).parent(), slideActiveOld);
                            slideActiveOld = $(swiper.wrapperEl).parent().find('.swiper-slide-active').data('position');
                        }
                    },
                    slidePrevTransitionStart: function(swiper) {
                        var _selector = $(swiper.wrapperEl).parent();
                        if(!_selector.parent().hasClass('slider-dynamicPricing')) {
                            var activeSlide = _selector.find('.swiper-slide-active').data('position');

                            if(activeSlide < 1) {
                                _selector.find('.swiper-slide:first-child').css('margin-left', '0');
                            }
                        }
                    },
                    afterInit: function(swiper, event) {
                        $('.experience-commerce_layouts-dynamicPricing').css('display', 'block');
                        hiddenProductNameDescription(swiper);

                        onSwiperNext();
                        onSwiperPrev();

                        hasSwiperDragging($(swiper.wrapperEl));
                        $(swiper.wrapperEl).parent().parent().addClass('slider-initialized');
                        setTimeout(function () {
                            //loadImg();
                        }, 1000);

                        var _selector = $(swiper.wrapperEl).parent();
                        if(_selector.hasClass('swiper-product-cyclers')) {
                            _selector.closest('.layout-image-product-carousel').css('height', 'inherit');
                            _selector.closest('.layout-image-product-carousel').find('.image-cropper').show();
                            _selector.closest('.layout-image-product-carousel').find('.list-tabs').show();
                            _selector.closest('.layout-image-product-carousel').find('.btn-view-all-product').show();
                        }
                    },
                    resize: function(swiper, event) {
                        disabledSwiperButton($(swiper.wrapperEl).parent());
                    },
                    transitionEnd: function(swiper, event) {
                        disabledSwiperButton($(swiper.wrapperEl).parent());
                        //loadImg();
                    },
                    sliderFirstMove: function(swiper, event) {
                        const breakpoint = window.matchMedia('(max-width:767px)');
                        if (!breakpoint.matches) {
                            var _selector = $(swiper.wrapperEl).parent();
                            if(!_selector.parent().hasClass('slider-dynamicPricing')) {
                                numberTX = getTransformTX($(swiper.wrapperEl));
                            }

                            if(flag >= 3) {
                                flag = 0;
                            }
                        }
                    },
                    sliderMove: function(swiper, event) {
                        const breakpoint = window.matchMedia('(max-width:767px)');
                        if (!breakpoint.matches) {
                            if(flag <= 3) {
                                flag++;
                                var _selector = $(swiper.wrapperEl).parent();
                                if(!_selector.parent().hasClass('slider-dynamicPricing')) {
                                    var activeSlide = _selector.find('.swiper-slide-active').data('position');
                                    var currentNumberTX = getTransformTX($(swiper.wrapperEl));

                                    if(currentNumberTX > numberTX) {
                                        // next
                                        actionNext(_selector);
                                    } else {
                                        // prev
                                        actionPrev(_selector);
                                    }
                                }
                            }
                        }
                    }
                }
            });

            var loadSwiperDiv = $this.find('.load-swiper');
            if (loadSwiperDiv.length) {
                loadSwiperDiv.attr('loaded', 'true');
            }
        });
        var tabSwiper = new Swiper(".tabSwiper", {
            slidesPerView: "auto",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                resize: function(swiper, event) {
                    disabledSwiperButton($(swiper.wrapperEl).parent());
                },
                transitionEnd: function(swiper, event) {
                    disabledSwiperButton($(swiper.wrapperEl).parent());
                }
            }
        });
    }, 3000);

    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const landscape = window.matchMedia("(orientation: landscape)").matches;
    if (!isMobile && landscape) {
        $('body').on('click', '.swiper-button-next', function () {
            var _selector = $(this).closest('.swiper-container');

            if(_selector.parent().hasClass('slider-dynamicPricing')) {
                if(_selector.find('.swiper-slide').data('position') === 0){
                    if (landscape) {
                        _selector.find('.swiper-slide:first-child').css('margin-left', '-150px');
                    } else {
                        _selector.find('.swiper-slide:first-child').css('margin-left', '-200px');
                    }
                }

                var lastChild = $('.swiper-slide:last-child').data('position');
                var active = $('.swiper-slide-active').data('position');
                var total = lastChild - active;

                if(total === 3) {
                    _selector.find('.swiper-slide:first-child').css('margin-left', '0');
                }
            }
        });

        $('body').on('click', '.swiper-button-prev', function () {
            var _selector = $(this).closest('.swiper-container');

            if(_selector.parent().hasClass('slider-dynamicPricing')) {
                if(_selector.find('.swiper-slide').data('position') === 0){
                    if (landscape) {
                        _selector.find('.swiper-slide:first-child').css('margin-left', '-150px');
                    } else {
                        _selector.find('.swiper-slide:first-child').css('margin-left', '-200px');
                    }
                }

                var active = $('.swiper-slide-active').data('position');

                if (active == 0) {
                    _selector.find('.swiper-slide:first-child').css('margin-left', '0');
                }
            }
        });
    }

    $('body').on('click', '.swiper-button-prev', function () {
        var _selector = $(this).closest('.swiper-container');
        actionPrev(_selector);
    });

    $('body').on('click', '.swiper-button-next', function () {
        var _selector = $(this).closest('.swiper-container');
        actionNext(_selector);
    });

    $('body').on("mouseover", '.swiper-wrapper', function (e) {
        disabledSwiperButton($(this).parent());
    });

    $('body').on('click', '.product-tile-card', function () {
        var _selector = $(this).closest('.swiper-container');
        if(_selector.parent().hasClass('slider-dynamicPricing')) {
            var data = {
                event: "dynamic_product_click",
                ecommerce: {
                    dynamic_product_link: $(this).find('a').attr('href'),
                    click_source: 'Dynamic-Carousel'
                }
            };
            window.dataLayer.push(data);
        }
    });
}

$(document).ready(function () {
    handleCategoryTab();

    loadSwiper();

    var count = 0;
    var swiperInterval = setInterval(function() {
        if ($('.load-swiper').length) {
            var isLoadSwiper = false;
            $('.load-swiper').each(function() {
                var isLoaded = $(this).attr('loaded');
                if (!isLoaded) {
                    isLoadSwiper = true;
                }
            });
            if (isLoadSwiper) {
                loadSwiper();
            }
        }

        count += 1;

        if (count > 15) {
            clearInterval(swiperInterval);
        }
    }, 2000);
});

function getTransformTX(wrapperEl) {
    var str = wrapperEl.css('transform');
    var x = str.split(',');
    return Math.abs(x[x.length-2]);
}

function actionNext(_selector) {
    // not slider dynamicPricing
    if(!_selector.parent().hasClass('slider-dynamicPricing')) {
        _selector.find('.swiper-slide:first-child').css('margin-left', '0');
    }
}

function actionPrev(_selector) {
    // not slider dynamicPricing
    if(!_selector.parent().hasClass('slider-dynamicPricing')) {
        var active = _selector.find('.swiper-slide-active').data('position');

        if (active <= 1) {
            if (!_selector.find('.swiper-button-next').hasClass('swiper-button-disabled') || active === 0) {
                _selector.find('.swiper-slide:first-child').css('margin-left', '0');
            }
        } else {
            const breakpoint = window.matchMedia('(max-width:992px)');
            if (!breakpoint.matches && active > 1) {
                //desktop
                if(_selector.hasClass('swiper-product-cyclers')) {
                    _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(18.5% + 60px)');
                } else {
                    _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(13.5% + 60px)');
                }
            } else {
                // ipad
                _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(18.5% + 60px)');
            }
        }
    }
}

function setMarginLeft(_selector, slideActiveOld) {
    const breakpoint = window.matchMedia('(max-width:992px)');
    var numberTotal = 3;

    if(_selector.hasClass('swiper-product-cyclers') || breakpoint.matches) {
        numberTotal = 2
    }

    if(!_selector.parent().hasClass('slider-dynamicPricing')) {
        _selector.find('.swiper-slide:first-child').css('margin-left', '0');
        var activeSlide = _selector.find('.swiper-slide-active').data('position');
        var lastSlideChild = _selector.find('.swiper-slide:last-child').data('position');

        if (slideActiveOld >= activeSlide) {
            // scrollbar back
            if(activeSlide < 1) {
                _selector.find('.swiper-slide:first-child').css('margin-left', '0');
            } else {

                if(activeSlide === 1 && _selector.find('.swiper-button-prev').hasClass('swiper-button-disabled')) {
                    _selector.find('.swiper-slide:first-child').css('margin-left', '0');
                } else {
                    var total = lastSlideChild - activeSlide;
                    if(total === numberTotal) {
                        _selector.find('.swiper-slide:first-child').css('margin-left', '0');
                    } else {
                        if (!breakpoint.matches) {
                            //desktop
                            if(_selector.hasClass('swiper-product-cyclers')) {
                                _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(18.5% + 60px)');
                            } else {
                                _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(13.5% + 60px)');
                            }
                        } else {
                            // ipad
                            _selector.find('.swiper-slide:first-child').css('margin-left', 'calc(18.5% + 60px)');
                        }
                    }
                }
            }
        } else {
            _selector.find('.swiper-slide:first-child').css('margin-left', '0');
        }
    }
}

function disabledSwiperButton(oSlider) {
    if (oSlider.find('.swiper-button-prev').hasClass('swiper-button-disabled')) {
        oSlider.parent().find('.swiper-arrow-prev').addClass('swiper-button-disabled');
    } else {
        oSlider.parent().find('.swiper-arrow-prev').removeClass('swiper-button-disabled');
    }

    if (oSlider.find('.swiper-button-next').hasClass('swiper-button-disabled')) {
        oSlider.parent().find('.swiper-arrow-next').addClass('swiper-button-disabled');
    } else {
        oSlider.parent().find('.swiper-arrow-next').removeClass('swiper-button-disabled');
    }
}

function hasSwiperDragging(oSlider) {
    oSlider.parent().parent().addClass('no-swiper-dragging');

    if(oSlider.parent().find('.swiper-drag').length && oSlider.parent().find('.swiper-drag').css('display') == 'block') {
        oSlider.parent().parent().removeClass('no-swiper-dragging');
    }
}

function loadImg() {
    $('.swiper-slide img:not(.lazy-loaded)').each(function() {
        if ($(this).attr('data-src')) {
            $(this).attr('src', $(this).attr('data-src'));
        }
    });
}

function onSwiperPrev() {
    $('.swiper-arrow-prev').off().on('click', function() {
        $(this).parent().find('.swiper-button-prev').trigger('click');
    });
}

function onSwiperNext() {
    $('.swiper-arrow-next').off().on('click', function() {
        $(this).parent().find('.swiper-button-next').trigger('click');
    });
}

function hiddenProductNameDescription(swiper) {
    var $el = swiper.$el[0];
    var $displayProductName = $el.getAttribute('data-display-product-name');
    var $displayProductDescription = $el.getAttribute('data-display-product-description');
    if ($displayProductName === "false") {
        $(swiper.wrapperEl).parent().find('.product-tile-name-wrap').addClass('d-none');
    }
    if ($displayProductDescription === "false") {
        $(swiper.wrapperEl).parent().find('.product-tile-range').addClass('d-none');
        $(swiper.wrapperEl).parent().find('.product-tile-subtitle').addClass('d-none');
    }
}

function handleCategoryTab() {
    var $categoryTabContent = $(".categoryTabContent");
    if ($categoryTabContent.find(".category-item").hasClass('active')) {
        $categoryTabContent.find(".category-item:first").find('.category-item').removeClass('d-none');
        $categoryTabContent.find(".category-item:first").find('.category-item').addClass('show');
    }

    $('.categoryTab button').on('click', function (event) {
        event.preventDefault();
        $(this).tab('show');
        var $parentClass = $(this).parents('.image-product-carousel-inner');
        $parentClass.find('.tab-pane').addClass('d-none');
        var $idTabContent = $(this).attr('data-target');
        $parentClass.find($idTabContent).removeClass('d-none');
        $parentClass.find($idTabContent).addClass('show');
        var pid = $parentClass.find('.product-grid-mattress').attr('data-pid');
        $parentClass.find('.mattress-overlays-list').each(function () {
            if ($(this).attr('data-product-id') == pid) {
                $(this).removeClass('d-none');
            }
        });
    });
}